<script setup lang="ts">
import { Button } from '@temperworks/components'
import { ButtonGroupVariant, ButtonContext } from '@temperworks/types'

interface Props {
  variant: ButtonGroupVariant
  backButton: ButtonContext
  nextButton: ButtonContext
}
const props = defineProps<Props>()
const emit = defineEmits(['next', 'back'])
function next(item) {
  emit('next', item)
}
function back(item) {
  emit('back', item)
}
</script>

<template>
  <div
    class="button-group"
    :class="props.variant"
  >
    <Button
      :variant="props.backButton.variant"
      :label="props.backButton.label"
      :disabled="props.backButton.disabled"
      :loading="props.backButton.loading"
      :icon="props.backButton.icon"
      :badge="props.backButton.badge"
      @click="back($event)"
    />
    <Button
      :variant="props.nextButton.variant"
      :label="props.nextButton.label"
      :disabled="props.nextButton.disabled"
      :loading="props.nextButton.loading"
      :icon="props.nextButton.icon"
      :badge="props.nextButton.badge"
      @click="next($event)"
    />
  </div>
</template>

<style lang="scss" scoped>
@use './ButtonGroup.scss';
</style>
